<script setup lang="ts">
import type { Legend } from "@/stores/UserState";
import UserState from "@/stores/UserState";
import { useLongPressTooltip } from "@/components/composable/useLongPressTooltip";
import CanvasSettingsState from "@/stores/CanvasSettingsState";
import { Logger } from "@/logger";

import { useLegendConfigurator } from "@/components/composable/useLegendConfigurator";

const { refHook } = useLongPressTooltip();

const configurator = useLegendConfigurator();

function activateLegend(legend: Legend) {
  Logger.info(`Legend.vue : activating legend ${legend.name}`);
  CanvasSettingsState.onLegendChange(legend);
  Logger.info(`Legend.vue : legend ${legend.name} activated`);
}

function openConfigurator(legend: Legend) {
  configurator?.show(legend, "readonly");
}
</script>

<template>
  <div
    class="btn-group dropup"
    ref="refHook"
    data-bs-content="La légende définit plusieurs éléments :
                        <ul>
                          <li>La liste des désordres et leurs propriétés (couleur, épaisseur de trait, ...)</li>
                          <li>Les styles et échelles de textes</li>
                          <li>La liste des calques utilisés pour annoter et à créer</li>
                        </ul>"
    data-bs-title="Légende"
  >
    <button
      type="button"
      class="btn btn-secondary dropdown-toggle"
      data-bs-auto-close="true"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{
        CanvasSettingsState.activeLegend
          ? CanvasSettingsState.activeLegend.name
          : "Légende"
      }}
    </button>
    <ul class="dropdown-menu dropdown-menu-dark">
      <li
        class="d-flex align-items-center px-2"
        v-for="legend in UserState.data?.legends"
        :key="legend.id"
      >
        <a
          @click="activateLegend(legend)"
          class="dropdown-item d-flex justify-content-between"
          :class="{
            active: CanvasSettingsState.activeLegend?.name === legend.name,
          }"
        >
          {{ legend.name }}
        </a>
        <i
          class="px-2 bi bi-box-arrow-up-right"
          @click.stop="openConfigurator(legend)"
        ></i>
      </li>
    </ul>
  </div>
</template>
@/stores/CanvasSettingsState@/stores/UserState@/stores/UserState
