import { reactive, toRaw } from "vue";
import {
  type DraggerConfig,
  DraggerName,
} from "../open-cloud/draggers/draggers.type";
import { Facade } from "../open-cloud/Facade";
import NoteMenuState from "./NoteMenuState";
import type { StoredSettings } from "./CanvasSettingsState";
import _ from "lodash";
import CanvasSettingsState from "./CanvasSettingsState";
import Settings from "@/stores/Settings";
import { Logger } from "@/logger";

type DraggerMenuState = {
  activeDragger: DraggerConfig;
  activePhotoMarkerShape: "arrow" | "circle";
  label: boolean;
  storedSettings: StoredSettings | null;
  origin: [number, number, number];
  closeContour: boolean;
  smoothen: boolean;
  initialize: (storedSettings: StoredSettings | null) => void;
  onDrawingOpen: () => void;
  setActiveDragger: (dragger: DraggerConfig) => void;
  setActiveText: (text: string) => void;
  setPhotoMarkerShape: (shape: "arrow" | "circle") => void;
  persistOrigin: (coord: [number, number, number]) => void;
  toggleCloseContour: () => void;
  toggleLabel: () => void;
  toggleSmoothen: () => void;
};

export default reactive<DraggerMenuState>({
  activePhotoMarkerShape: "arrow",
  label: true,
  activeDragger: { name: DraggerName.NoDragger },
  origin: [0, 0, 0],
  storedSettings: null,
  closeContour: true,
  smoothen: true,
  initialize(storedSettings: StoredSettings | null) {
    this.storedSettings = storedSettings;
    this.activeDragger.name =
      storedSettings?.dragger?.name || DraggerName.NoDragger;
    this.activePhotoMarkerShape =
      storedSettings?.dragger?.photoMarkerShape || "arrow";
    this.origin = storedSettings?.dragger?.origin || [0, 0, 0];
    if (storedSettings?.dragger?.label != undefined) {
      this.label = storedSettings.dragger.label;
    } else {
      this.label = true;
    }
    if (storedSettings?.dragger?.closeContour != undefined) {
      this.closeContour = storedSettings.dragger.closeContour;
    }
    if (storedSettings?.dragger?.smoothen != undefined) {
      this.smoothen = storedSettings.dragger.smoothen;
    }
  },
  onDrawingOpen() {
    Facade.setActiveDragger(toRaw(this.activeDragger));
    Facade.setOrigin(this.origin);
    Facade.setCloseContour(this.closeContour);
    Facade.setLabel(this.label);
    Facade.setSmoothen(this.smoothen);
    if (this.activePhotoMarkerShape === "arrow") {
      Facade.setPhotoMarkerShape(toRaw(Settings.arrowShape));
    } else {
      Facade.setPhotoMarkerShape("circle");
    }
  },
  setActiveDragger(dragger: DraggerConfig) {
    Facade.setActiveDragger(toRaw(dragger));
    Facade.resetSelectionSet();
    const isChangingToPhoto =
      dragger.name == DraggerName.PhotoMarker &&
      this.activeDragger.name != DraggerName.PhotoMarker;
    if (isChangingToPhoto) {
      NoteMenuState.setActiveNoteConfig(
        NoteMenuState.getPhotoNoteConfig(),
        true
      );
    }
    const isLeavingToPhoto =
      this.activeDragger.name == DraggerName.PhotoMarker &&
      dragger.name != DraggerName.PhotoMarker;
    if (isLeavingToPhoto) {
      if (NoteMenuState.previousNote) {
        NoteMenuState.setActiveNoteConfig(NoteMenuState.previousNote);
      } else {
        Logger.warn(
          `DraggerMenuState : user is leaving photo but not previous defined`
        );
      }
    }

    this.activeDragger = dragger;
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.name",
      this.activeDragger.name
    );
    CanvasSettingsState.updateAndPersist();
  },
  setActiveText(text: string) {
    Facade.setActiveText(toRaw(text));
  },

  setPhotoMarkerShape(shape) {
    this.activePhotoMarkerShape = shape;
    if (shape === "arrow") {
      Facade.setPhotoMarkerShape(toRaw(Settings.arrowShape));
    } else {
      Facade.setPhotoMarkerShape("circle");
    }

    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.photoMarkerShape",
      this.activePhotoMarkerShape
    );

    CanvasSettingsState.updateAndPersist();
  },
  persistOrigin(coord: [number, number, number]) {
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.origin",
      coord
    );
    CanvasSettingsState.updateAndPersist();
  },
  toggleCloseContour() {
    this.closeContour = !this.closeContour;
    Facade.setCloseContour(toRaw(this.closeContour));
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.closeContour",
      this.closeContour
    );
    CanvasSettingsState.updateAndPersist();
  },
  toggleSmoothen() {
    this.smoothen = !this.smoothen;
    Facade.setSmoothen(toRaw(this.smoothen));
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.smoothen",
      this.smoothen
    );
    CanvasSettingsState.updateAndPersist();
  },
  toggleLabel() {
    this.label = !this.label;
    this.storedSettings = _.set(
      this.storedSettings || {},
      "dragger.label",
      this.label
    );
    Facade.setLabel(this.label);
    CanvasSettingsState.updateAndPersist();
  },
});
