import type { HatchPatternWithName } from "./library";

export const DR_HATCHES: HatchPatternWithName[] = [
  {
    name: "ANGLE",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0, 0.275],
            dashes: [0.2, -0.075],
            basePoint: [0, 0],
          },
          {
            angle: 1.5707,
            offset: [0.275, 0],
            dashes: [0.2, -0.075],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "ANSI31",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0, 0.5],
          },
        ],
      },
    },
  },
  {
    name: "ANSI32",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0, 1.5],
          },
          {
            angle: 0.7853,
            offset: [0, 1.5],
            basePoint: [0.5, 0],
          },
        ],
      },
    },
  },
  {
    name: "ANSI33",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0, 1],
            dashes: [],
            basePoint: [0, 0],
          },
          {
            angle: 0.7853,
            offset: [0, 1],
            dashes: [0.5, -0.25],
            basePoint: [0.5, 0],
          },
        ],
      },
    },
  },
  {
    name: "ANSI36",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0.875, 0.5],
            dashes: [1.25, -0.25, 0, -0.25],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "ANSI37",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0, 0.5],
            dashes: [],
            basePoint: [0, 0],
          },
          {
            angle: 2.3561,
            offset: [0, 0.5],
            dashes: [],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "ANSI38",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.7853,
            offset: [0, 0.5],
            dashes: [],
            basePoint: [0, 0],
          },
          {
            angle: 2.3561,
            offset: [1, 0.5],
            dashes: [1.25, -0.75],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "AR-B816",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0, 2],
            dashes: [],
            basePoint: [0, 0],
          },
          {
            angle: 1.5707,
            offset: [2, 2],
            dashes: [2, -2],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "AR-CONC",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.8726,
            offset: [16.51900136 * 0.25, -23.59157888 * 0.25],
            dashes: [3 * 0.25, -33 * 0.25],
            basePoint: [0, 0],
          },
          {
            angle: 6.1959,
            offset: [-8.15124828 * 0.25, 29.4894736 * 0.25],
            dashes: [2.4 * 0.25, -26.4 * 0.25],
            basePoint: [0, 0],
          },
          {
            angle: 1.7532,
            offset: [22.9223484 * 0.25, -27.7590692 * 0.25],
            dashes: [2.54960768 * 0.25, -28.04568448 * 0.25],
            basePoint: [2.39086724 * 0.25, -0.20917376 * 0.25],
          },
          {
            angle: 0.806,
            offset: [24.77850216 * 0.25, -35.38738384 * 0.25],
            dashes: [4.5 * 0.25, -49.5 * 0.25],
            basePoint: [0, 8 * 0.25],
          },
          {
            angle: 1.6866,
            offset: [34.38352956 * 0.25, -41.63859864 * 0.25],
            dashes: [3.82441368 * 0.25, -42.0685504 * 0.25],
            basePoint: [3.5574698 * 0.25, 7.44826772 * 0.25],
          },
          {
            angle: 6.1293,
            offset: [30.97309976 * 0.25, 44.23422984 * 0.25],
            dashes: [3.6 * 0.25, -39.60000004 * 0.25],
            basePoint: [0, 8 * 0.25],
          },
          {
            angle: 0.3665,
            offset: [16.51900136 * 0.25, -23.59157888 * 0.25],
            dashes: [3 * 0.25 , -33 * 0.25],
            basePoint: [4 * 0.25, 6 * 0.25],
          },
          {
            angle: 5.6897,
            offset: [-8.15124828 * 0.25, 29.4894736 * 0.25],
            dashes: [2.4 * 0.25, -26.4 * 0.25],
            basePoint: [4 * 0.25, 6 * 0.25],
          },
          {
            angle: 1.247,
            offset: [22.9223484 * 0.25, -27.7590692 * 0.25],
            dashes: [2.5496076 * 0.25, -28.04568448 * 0.25],
            basePoint: [5.98969016 * 0.25, 4.65793704 * 0.25],
          },
          {
            angle: 0.6544,
            offset: [8.492 * 0.25, 10.268 * 0.25],
            dashes: [0, -26.08 * 0.25, 0, -26.8 * 0.25, 0, -26.5 * 0.25],
            basePoint: [0, 0],
          },
          {
            angle: 0.1308,
            offset: [12.492 * 0.25, 14.268 * 0.25],
            dashes: [0, -15.28 * 0.25, 0, -25.48 * 0.25, 0, -10.1 * 0.25],
            basePoint: [0, 0],
          },
          {
            angle: -0.5672,
            offset: [18.4936 * 0.25, 10.712 * 0.25],
            dashes: [0, -10 * 0.25, 0, -31.2 * 0.25, 0, -41.4 * 0.25],
            basePoint: [-8.92 * 0.25, 0],
          },
          {
            angle: -0.7417,
            offset: [14.4936 * 0.25, 18.712 * 0.25],
            dashes: [0, -13 * 0.25, 0, -20.72 * 0.25, 0, -29.4 * 0.25],
            basePoint: [-12.92 * 0.25, 0],
          },
        ],
      },
    },
  },
  {
    name: "AR-RROOF",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [2.2, 1],
            dashes: [15, -2, 5, -1],
            basePoint: [0, 0],
          },
          {
            angle: 0,
            offset: [-1, 1.33],
            dashes: [3, -0.33, 6, -3 / 4],
            basePoint: [1.33 / 4, 0.5],
          },
          {
            angle: 0,
            offset: [5.2, 2.68 / 4],
            dashes: [8, -1.4, 4, -1],
            basePoint: [0.5, 3.4 / 4],
          },
        ],
      },
    },
  },
  {
    name: "AR-SAND",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0.6544,
            offset: [1.123, 1.567],
            dashes: [0, -1.52, 0, -1.7, 0, -1.625],
            basePoint: [0, 0],
          },
          {
            angle: 0.1308,
            offset: [2.123, 2.567],
            dashes: [0, -0.82, 0, -1.37, 0, -0.525],
            basePoint: [0, 0],
          },
          {
            angle: -0.5672,
            offset: [2.6234, 1.678],
            dashes: [0, -0.5, 0, -1.8, 0, -2.35],
            basePoint: [-1.23, 0],
          },
          {
            angle: -0.7417,
            offset: [1.6234, 2.678],
            dashes: [0, -0.25, 0, -1.18, 0, -1.35],
            basePoint: [-1.23, 0],
          },
        ],
      },
    },
  },
  {
    name: "AR-RSHKE",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [2.04 * 1.5, 0.96 * 1.5],
            dashes: [0.48 * 1.5, -0.4 * 1.5, 0.56 * 1.5, -0.24 * 1.5, 0.72 * 1.5, -0.32 * 1.5],
            basePoint: [0.0, 0.0],
          },
          {
            angle: 0,
            offset: [2.04 * 1.5, 0.96 * 1.5],
            dashes: [0.4 * 1.5, -1.52 * 1.5, 0.32 * 1.5, -0.48 * 1.5],
            basePoint: [0.48 * 1.5, 0.04 * 1.5],
          },
          {
            angle: 0,
            offset: [2.04 * 1.5, 0.96 * 1.5],
            dashes: [0.24 * 1.5, -2.48 * 1.5],
            basePoint: [1.44 * 1.5, -0.06 * 1.5],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.92 * 1.5, -2.92 * 1.5],
            basePoint: [0.0, 0.0],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.9 * 1.5, -2.94 * 1.5],
            basePoint: [0.48, 0.0],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.84 * 1.5, -3.0 * 1.5],
            basePoint: [0.88 * 1.5, 0.0],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.92 * 1.5, -2.92 * 1.5],
            basePoint: [1.44 * 1.5, -0.06 * 1.5],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.92 * 1.5, -2.92 * 1.5],
            basePoint: [1.68 * 1.5, -0.06 * 1.5],
          },
          {
            angle: 1.5707,
            offset: [0.96 * 1.5, 0.68 * 1.5],
            dashes: [0.88 * 1.5, -2.96 * 1.5],
            basePoint: [2.4 * 1.5, 0.0],
          },
        ],
      },
    },
  },
  {
    name: "CROSS",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [1, 1],
            dashes: [0.5, -1.5],
            basePoint: [0, 0],
          },
          {
            angle: Math.PI / 2,
            offset: [1, 1],
            dashes: [0.5, -1.5],
            basePoint: [0.25, -0.25],
          },
        ],
      },
    },
  },
  {
    name: "DASH",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0.5, 0.5],
            dashes: [0.5, -0.5],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "DOTS",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0.125, 0.25],
            dashes: [0, -0.25],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "FLEX",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0, 1],
            dashes: [1, -1],
            basePoint: [0, 0],
          },
          {
            angle: Math.PI / 4,
            offset: [0, 1],
            dashes: [
              0.25,
              -(1 / Math.cos(Math.PI / 4) - 2 * 0.25),
              0.25,
              -1 / Math.cos(Math.PI / 4),
            ],
            basePoint: [1, 0],
          },
        ],
      },
    },
  },
  {
    name: "GRASS",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: Math.PI / 4,
            offset: [1.2, 0],
            dashes: [
              (1.2 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.2 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: (3 * Math.PI) / 4,
            offset: [1.2, 0],
            dashes: [
              (1.2 / Math.cos(Math.PI / 4)) * 0.3,
              -(1.2 / Math.cos(Math.PI / 4)) * 0.7,
            ],
          },
          {
            angle: Math.PI / 2,
            offset: [1.2, 0],
            dashes: [0.6, -0.6],
          },
        ],
      },
    },
  },
  {
    name: "HEX",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [
              (0.25 + 0.5) * Math.cos(Math.PI / 3),
              (0.25 + 0.5) * Math.sin(Math.PI / 3),
            ],
            dashes: [0.25, -0.5],
            basePoint: [0, 0],
          },
          {
            angle: Math.PI / 3,
            offset: [2 * (0.25 + 0.5) * Math.cos(Math.PI / 3), 0],
            dashes: [0.25, -0.5],
            basePoint: [0.25, 0],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [
              (0.25 + 0.5) * Math.cos(Math.PI / 3),
              (0.25 + 0.5) * Math.sin(Math.PI / 3),
            ],
            dashes: [0.25, -0.5],
            basePoint: [0.25 * 1.5, (Math.sqrt(3) * 0.25) / 2],
          },
          {
            angle: Math.PI,
            offset: [
              (0.25 + 0.5) * Math.cos(Math.PI / 3),
              (0.25 + 0.5) * Math.sin(Math.PI / 3),
            ],
            dashes: [0.25, -0.5],
            basePoint: [0.25, Math.sqrt(3) * 0.25],
          },
          {
            angle: (Math.PI * 4) / 3,
            offset: [2 * (0.25 + 0.5) * Math.cos(Math.PI / 3), 0],
            dashes: [0.25, -0.5],
            basePoint: [0, Math.sqrt(3) * 0.25],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [
              (0.25 + 0.5) * Math.cos(Math.PI / 3),
              (0.25 + 0.5) * Math.sin(Math.PI / 3),
            ],
            dashes: [0.25, -0.5],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "HONEY",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0.5 + 0.5 / 2, (Math.sqrt(3) * 0.5) / 2],
            dashes: [0.5, -1],
            basePoint: [0, 0],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [0.5 + 0.5 / 2, (Math.sqrt(3) * 0.5) / 2],
            dashes: [0.5, -1],
            basePoint: [0, 0],
          },
          {
            angle: Math.PI / 3,
            offset: [0.5 + 0.5 / 2, (Math.sqrt(3) * 0.5) / 2],
            dashes: [0.5, -1],
            basePoint: [0.5, 0],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [0.5 + 0.5 / 2, (Math.sqrt(3) * 0.5) / 2],
            dashes: [0.5, -1],
            basePoint: [0, Math.sqrt(3) * 0.5],
          },
        ],
      },
    },
  },
  {
    name: "HOUND",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [1 * 1.5, 0.25 * 1.5],
            dashes: [4 * 1.5, -2 * 1.5],
            basePoint: [0, 0],
          },
          {
            angle: 1.5707,
            offset: [-1 * 1.5, 0.25 * 1.5],
            dashes: [4 * 1.5, -2 * 1.5],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "HUM",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: Math.PI / 4,
            offset: [4 * (0.9 * Math.cos(Math.PI / 4)), 0],
            basePoint: [0, - 1 / 3 * 0.9 * Math.sin(Math.PI / 4)],
            dashes: [
              0.9,
              -0.9,
            ],
          },
          {
            angle: Math.PI / 4,
            basePoint: [0.9 / 3 *  Math.cos(Math.PI / 4), - 2 * 0.9 / 3 *  Math.sin(Math.PI / 4)],
            offset: [4 * (0.9 * Math.cos(Math.PI / 4)), 0],
            dashes: [
              0.9,
              -0.9,
            ],
          },
          {
            angle: - Math.PI / 4,
            basePoint: [0, 1 / 3 * 0.9 * Math.sin(Math.PI / 4)],
            offset: [4 * (0.9 * Math.cos(Math.PI / 4)), 0],
            dashes: [
              0.9,
              -0.9,
            ],
          },
          {
            angle: - Math.PI / 4,
            basePoint: [ 0.9 / 3 *  Math.cos(Math.PI / 4), 2 * 0.9 / 3 *  Math.sin(Math.PI / 4)],
            offset: [4 * (0.9 * Math.cos(Math.PI / 4)), 0],
            dashes: [
              0.9,
              -0.9,
            ],
          },
        ],
      },
    },
  },
  {
    name: "MUDST",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [2, 1],
            dashes: [1, -1, 0, -1, 0, -1],
            basePoint: [0, 0],
          },
        ],
      },
    },
  },
  {
    name: "SWAMP",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0, (0.25 + 0.5) * Math.sin(Math.PI / 3) * 1.5],
            dashes: [0.25 * 1.5, -0.5 * 1.5],
            basePoint: [0, 0],
          },
          {
            angle: Math.PI / 3,
            offset: [0, (0.25 + 0.5) * Math.sin(Math.PI / 3) * 1.5],
            dashes: [0.2 * 1.5, -((0.25 + 0.5) / Math.cos(Math.PI / 3) - 0.2) * 1.5],
            basePoint: [0.2 * 1.5, 0],
          },
          {
            angle: Math.PI / 2,
            offset: [2 * (0.25 + 0.5) * Math.cos(Math.PI / 3) * 1.5, 0],
            dashes: [
              0.25 * Math.sin(Math.PI / 3) * 1.5,
              -0.5 * Math.sin(Math.PI / 3) * 1.5,
            ],
            basePoint: [0.125 * 1.5, 0],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [0, (0.25 + 0.5) * Math.sin(Math.PI / 3) * 1.5],
            dashes: [0.2 * 1.5, -((0.25 + 0.5) / Math.cos(Math.PI / 3) - 0.2) * 1.5],
            basePoint: [0.05 * 1.5, 0],
          },
        ],
      },
    },
  },
  {
    name: "TRIANG",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: Math.PI / 3,
            offset: [0.5 * 1.5, 0],
            dashes: [0.25 * 1.5, -0.25 * 1.5],
            basePoint: [0, 0],
          },
          {
            angle: (2 * Math.PI) / 3,
            offset: [0.5 * 1.5, 0],
            dashes: [0.25 * 1.5, -0.25 * 1.5],
            basePoint: [0, 0],
          },
          {
            angle: 0,
            offset: [0.25 * 1.5, 0.5 * Math.sin(Math.PI / 3) * 1.5],
            dashes: [0.25 * 1.5, -0.25 * 1.5],
            basePoint: [-0.125 * 1.5, 0.25 * Math.sin(Math.PI / 3) * 1.5],
          },
        ],
      },
    },
  },
  {
    name: "ANSI32-315-45",
    hatches: {
      transparency: 0,
      patternLines: {
        lines: [
          {
            angle: 0,
            offset: [0, 1.5],
          },
          {
            angle: 0,
            offset: [0, 1.5],
            basePoint: [0, 0.5],
          },
          {
            angle: Math.PI / 2,
            offset: [1.5, 0],
          },
          {
            angle: Math.PI / 2,
            offset: [1.5, 0],
            basePoint: [0.5, 0],
          },
        ],
      },
    },
  },
];
