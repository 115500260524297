import type { NoteConfig } from "./UserState";
import type { StoredSettings } from "./CanvasSettingsState";
import { reactive, toRaw } from "vue";
import CanvasSettingsState from "./CanvasSettingsState";
import {
  DEFAULT_LEGEND,
  DEFAULT_NOTE_CONFIG,
  DEFAULT_PHOTO_NOTE_CONFIG,
} from "../defaultLegend";
import { Facade } from "../open-cloud/Facade";
import { DraggerName } from "../open-cloud/draggers/draggers.type";
import _ from "lodash";
import DraggerMenuState from "./DraggerMenuState";
import ScaleMenuState from "./ScaleMenuState";

interface NoteMenuState {
  activeNote: NoteConfig;
  activeCategories: string[];
  noteConfigs: NoteConfig[];
  storedSettings: StoredSettings | null;
  previousNote?: NoteConfig;
  initialize: (storedSettings: StoredSettings | null) => void;
  onDrawingOpen: (userConfigs: NoteConfig[]) => void;
  onLegendChange: (userConfigs: NoteConfig[]) => void;
  computeNoteConfig: (config?: NoteConfig) => NoteConfig;
  getPhotoNoteConfig: () => NoteConfig;
  updateComputedNoteConfig: () => NoteConfig | null;
  setActiveNoteConfig: (
    note: NoteConfig,
    shouldStorePrevious?: boolean
  ) => void;
  persistActiveCategories: () => void;
}

export default reactive<NoteMenuState>({
  activeNote: DEFAULT_NOTE_CONFIG,
  activeCategories: [],
  noteConfigs: DEFAULT_LEGEND.note_configs,
  storedSettings: null,
  initialize(storedSettings: StoredSettings | null) {
    this.storedSettings = storedSettings;
  },
  onDrawingOpen(userConfigs: NoteConfig[]) {
    this.noteConfigs = userConfigs;
    // retrieve stored state from local storage
    if (this.storedSettings?.note?.activeNoteIdx != undefined) {
      this.activeNote = userConfigs[this.storedSettings.note.activeNoteIdx];
    }
    if (this.storedSettings?.note?.previousNoteIdx != undefined) {
      this.previousNote = userConfigs[this.storedSettings.note.previousNoteIdx];
    }
    if (this.storedSettings?.note?.activeCategories) {
      this.activeCategories = this.storedSettings.note.activeCategories;
    }
  },
  onLegendChange(userConfigs: NoteConfig[]) {
    this.noteConfigs = userConfigs;
    this.activeNote = DEFAULT_NOTE_CONFIG;
    this.activeCategories = [];
  },
  // Merge selected default config and scale config to get the full config to apply
  // Do not set any properties the class, only returns the computed config (Raw).
  computeNoteConfig(config?: NoteConfig): NoteConfig {
    // clone active note
    if (!config) config = toRaw(this.activeNote);
    else config = toRaw(config);
    const computedConfig = structuredClone(config);
    if (ScaleMenuState.activeScale) {
      // modify the texstyle part with textstyle selected
      // Keep style props for text
      computedConfig.props.textstyle =
        ScaleMenuState.activeScale.props.textstyle;
    }
    if (ScaleMenuState.isTextsizeForced && ScaleMenuState.forcedSize != "") {
      // force size if checked
      computedConfig.props.textsize = ScaleMenuState.forcedSize;
    }
    return computedConfig;
  },
  getPhotoNoteConfig(): NoteConfig {
    for (const noteConfig of this.noteConfigs) {
      if (noteConfig.type == "photo") return noteConfig;
    }
    return DEFAULT_PHOTO_NOTE_CONFIG;
  },
  updateComputedNoteConfig(): NoteConfig | null {
    const computedConfig = this.computeNoteConfig();
    if (!computedConfig) return null;
    Facade.activateNoteConfig(toRaw(computedConfig));
    return computedConfig;
  },
  setActiveNoteConfig(note: NoteConfig, shouldStorePrevious = false) {
    if (shouldStorePrevious) {
      this.previousNote = this.activeNote;
      const idx = this.noteConfigs.findIndex(
        (note1) => note1 === this.activeNote
      );
      this.storedSettings = _.set(
        this.storedSettings || {},
        "note.previousNoteIdx",
        idx
      );
    } else {
      this.previousNote = undefined;
      this.storedSettings = _.set(
        this.storedSettings || {},
        "note.previousNoteIdx",
        undefined
      );
    }
    CanvasSettingsState.updateAndPersist();
    const idx = this.noteConfigs.findIndex((note1) => note1 === note);
    this.activeNote = note;
    const config = this.updateComputedNoteConfig();
    this.storedSettings = _.set(
      this.storedSettings || {},
      "note.activeNoteIdx",
      idx
    );
    if (DraggerMenuState.activeDragger.name == DraggerName.DrSelect && config) {
      //Facade.setSelectionProperty(toRaw(config));
    }
  },
  persistActiveCategories() {
    this.storedSettings = _.set(
      this.storedSettings || {},
      "note.activeCategories",
      this.activeCategories
    );
    CanvasSettingsState.updateAndPersist();
  },
});
