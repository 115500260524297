import type { HatchPattern } from "@/open-cloud/builders/HatchBuilder";
import { DR_HATCHES } from "./hatch-list";
import { cloneDeep } from "lodash";
export type HatchPatternWithName = {
  name: string;
  hatches: HatchPattern;
};

export default class HatchLibrary {
  // Returns a clone of the hatch pattern
  // angle is in degrees
  static getByName(
    name: string,
    angle?: number,
    flipY?: boolean
  ): HatchPatternWithName | undefined {
    //return undefined;
    for (const hatch of DR_HATCHES) {
      if (hatch.name === name) {
        const clone = cloneDeep(hatch);
        if (angle) HatchLibrary.rotate(clone, angle);
        if (flipY) HatchLibrary.flipY(clone);
        console.log(clone);
        return clone;
      }
    }
  }
  // return a clone of hatch rotated by an angle in degrees
  static rotate(
    hatch: HatchPatternWithName,
    angle: number
  ): HatchPatternWithName {
    const angleInRadians = (angle * Math.PI) / 180;
    for (const line of hatch.hatches.patternLines.lines) {
      if (line.angle != undefined) {
        line.angle = line.angle + angleInRadians;
      }
      if (line.basePoint) {
        line.basePoint = HatchLibrary.rotateVector(
          line.basePoint,
          angleInRadians
        );
      }
      if (line.offset) {
        line.offset = HatchLibrary.rotateVector(line.offset, angleInRadians);
      }
    }
    return hatch;
  }

  static flipY(hatch: HatchPatternWithName) {
    for (const line of hatch.hatches.patternLines.lines) {
      if (line.angle) {
        line.angle = Math.PI - line.angle;
      }
      if (line.basePoint) {
        line.basePoint[0] = -line.basePoint[0];
      }
      if (line.offset) {
        line.offset[0] = -line.offset[0];
      }
    }
  }

  // angle in radian
  static rotateVector(
    v: [x: number, y: number],
    angle: number
  ): [x: number, y: number] {
    return [
      v[0] * Math.cos(angle) - v[1] * Math.sin(angle),
      v[0] * Math.sin(angle) + v[1] * Math.cos(angle),
    ];
  }
}
