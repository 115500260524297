import type { DRViewer } from "@/open-cloud/DRViewer";
import { DraggerEvents } from "../draggers/draggers.type";
import { ModelBuilder } from "./ModelBuilder";
import Toolbox from "@/open-cloud/builders/ODAToolbox";
import { isIdOfEntity } from "@/open-cloud/types/oda.types";
import OdaGeometryUtils from "@/open-cloud/builders/odaGeometry.utils";
import { EntityBuilder, type EntityProps } from "./EntityBuilder";
import type { DeleteParams } from "../commands/DeleteEntities";
import type { ReplaceParams } from "../commands/ReplaceEntities";
import { HistoryEvents } from "../commands/History";

export class SelectionSetBuilder {
  viewer: DRViewer;

  constructor(viewer: DRViewer) {
    this.viewer = viewer;
    viewer.addEventListener(HistoryEvents.UndoEnded, () => {
      this.resetSelectionSet();
    });

    viewer.addEventListener(HistoryEvents.RedoEnded, () => {
      this.resetSelectionSet();
    });
  }

  get visLib(): typeof VisualizeJS {
    return this.viewer.visLib();
  }

  get visViewer(): VisualizeJS.Viewer {
    return this.viewer.visViewer();
  }

  get toolbox(): Toolbox {
    return this.viewer.toolbox;
  }

  iterateCurrentSelection(fn: (entity: VisualizeJS.OdTvEntityId) => void) {
    if (this.viewer.selectionSet && this.viewer.selectionSet.numItems() > 0) {
      const itr = this.viewer.selectionSet.getIterator();
      Toolbox.iterateBase(itr, fn, (itr) => itr.getEntity());
      itr.delete();
    }
  }
  highlightSelectionSet(
    selectionSet: VisualizeJS.OdTvSelectionSet | null,
    isHighlight: boolean
  ) {
    if (selectionSet && selectionSet.numItems() != 0) {
      const aView = this.visViewer.activeView;
      aView.highlightAll(selectionSet, isHighlight);
    }
  }

  logSelectionSetProperties(selectionSet: VisualizeJS.OdTvSelectionSet | null) {
    if (selectionSet) {
      this.iterateCurrentSelection((entityId) => {
        this.viewer.entityBuilder.logEntityProperties(entityId);
        entityId.delete();
      });
    }
  }
  // Instead of deleting selection, we should move it to another Model
  deleteSelection() {
    const params: DeleteParams = [];

    const fn = (entityId: VisualizeJS.OdTvEntityId) => {
      params.push({
        handle: EntityBuilder.getHandle(entityId),
        layername: EntityBuilder.getLayerName(entityId),
      });

      entityId.delete();
    };

    this.iterateCurrentSelection(fn);

    this.highlightSelectionSet(this.viewer.selectionSet, false);
    this.viewer.commandFactory.deleteEntities.execute(params);

    this.viewer.selectionSet = null;

    this.viewer.emitEvent({ type: DraggerEvents.SelectionDeleted });
    this.viewer.update();
  }

  duplicateEntity(
    aModel: VisualizeJS.TvModel,
    copyTypeOf: VisualizeJS.OdTvEntityId
  ): VisualizeJS.OdTvEntityId {
    const createdEntityId = this.toolbox.createEntityInModel(aModel, {
      sourceInsert: copyTypeOf,
      baseName: "duplicate",
    });

    if (isIdOfEntity(createdEntityId)) {
      // copy geometry and color
      const copy = copyTypeOf.openObject();
      copy.copyTo(createdEntityId);
      copy.delete();
    }

    const extent = EntityBuilder.getAgnosticExtent(copyTypeOf);
    const { min, max } = EntityBuilder.decodeExtent(extent);
    const xLength = max[0] - min[0];
    const yLength = max[1] - min[1];
    extent.delete();
    const shiftVector: VisualizeJS.Vector3 = [xLength / 3, -yLength / 3, 0];
    const matrix0 = OdaGeometryUtils.getTranslationMatrix(shiftVector);
    const matrix1 =
      EntityBuilder.getModelingMatrix(copyTypeOf).preMultBy(matrix0);
    EntityBuilder.setModelingMatrix(createdEntityId, matrix1);
    matrix0.delete();
    matrix1.delete();
    return createdEntityId;
  }

  copySelection() {
    const aModel = this.viewer.modelBuilder.findModel("ACTIVE");

    const duplicatedIds: Array<VisualizeJS.OdTvEntityId> | null = [];

    this.iterateCurrentSelection((entityId) => {
      const createdId = this.duplicateEntity(aModel, entityId);
      duplicatedIds.push(createdId);
    });

    if (duplicatedIds.length > 0) {
      const params =
        this.viewer.commandFactory.addEntities.getParams(duplicatedIds);
      this.viewer.commandFactory.addEntities.execute(params);
      // selection newly created entities
      this.selectEntitiesManually(duplicatedIds);
      // to autoselect only the first entity
      // this.selectEntityManually(duplicatedIds[0]);
    }

    this.viewer.emitEvent({ type: DraggerEvents.SelectionDuplicated });
    this.viewer.update();
    aModel.delete();
  }

  setSelectionProperty(props: EntityProps) {
    if (!this.viewer.selectionSet) return;
    const oldSet = this.viewer.selectionSet;
    const itr = oldSet.getIterator();
    this.viewer.selectionSet = new this.visLib.OdTvSelectionSet();
    const params: ReplaceParams = [];
    // unhighlight entities
    const aView = this.visViewer.activeView;
    aView.highlightAll(oldSet, false);
    const model = this.visViewer.getActiveModel();
    for (; !itr.done(); itr.step()) {
      const oldId = itr.getEntity();
      // clone entity
      const entId = ModelBuilder.cloneEntity(oldId, model);
      if (entId) {
        // set properties of clone
        this.viewer.entityBuilder.setProperties(entId, props);
        // add it to selection set
        this.viewer.selectionSet.appendEntity(entId);
        // push to replace params
        params.push({
          old: {
            handle: EntityBuilder.getHandle(oldId),
            layername: EntityBuilder.getLayerName(oldId),
          },
          replacer: {
            handle: EntityBuilder.getHandle(entId),
            layername: EntityBuilder.getLayerName(entId),
          },
        });
        oldId.delete();
        entId.delete();
      }
    }
    itr.delete();
    // Rehighlight selection set
    aView.highlightAll(this.viewer.selectionSet, true);
    // send replace params to history
    this.viewer.commandFactory.replaceEntities.execute(params);
    // send events to listeners (update grid)
    this.viewer.emitEvent({ type: DraggerEvents.SetSelectionProperty });
  }

  resetSelectionSetWithoutEmitEvent() {
    // un-highlight old selection set
    this.highlightSelectionSet(this.viewer.selectionSet, false);
    // override selection set
    this.viewer.selectionSet = new this.visLib.OdTvSelectionSet();
  }

  resetSelectionSet() {
    this.resetSelectionSetWithoutEmitEvent();
    this.viewer.emitEvent({ type: DraggerEvents.SelectionEnded });
    this.viewer.update();
  }

  selectEntityManually(entityId: VisualizeJS.OdTvEntityId) {
    this.selectEntitiesManually([entityId]);
  }

  selectEntitiesManually(entities: VisualizeJS.OdTvEntityId[]) {
    this.resetSelectionSetWithoutEmitEvent();

    for (const entityId of entities) {
      // @ts-expect-error: the resetSelectionSetWithoutEmitEvent initiate it, it won't be undefined
      this.viewer.selectionSet.appendEntity(entityId);
    }
    this.highlightSelectionSet(this.viewer.selectionSet, true);
    this.viewer.emitEvent({ type: DraggerEvents.SelectionEnded });
  }
  // return the entity in selection set or null
  getEntity(handle: string): VisualizeJS.OdTvEntityId | null {
    if (!this.viewer.selectionSet) return null;
    const itr = this.viewer.selectionSet.getIterator();
    for (; !itr.done(); itr.step()) {
      const entId = itr.getEntity();
      const entHandle = EntityBuilder.getHandle(entId);
      if (handle === entHandle) {
        itr.delete();
        return entId;
      } else {
        entId.delete();
      }
    }
    return null;
  }
}
